import { Group } from '../../group/entities/group'
import { apiUrl } from '../../infra/hostname'
import { HttpProvider } from '../../infra/http'
import { dt, locator } from '../../infra/locator'
import { Product } from '../../product/entities/product'

export class ClassificationProvider {
  http: HttpProvider

  constructor() {
    this.http = locator.get<HttpProvider>(dt.HttpProvider)
  }

  total = (): Promise<{ total: number }> => {
    return this.http.get(`${apiUrl}/classification/products/total`)
  }

  next = (): Promise<Product[]> => {
    return this.http.get(`${apiUrl}/classification/products/next`)
  }

  search = (term: string): Promise<Product[]> => {
    return this.http.get(`${apiUrl}/classification/products/search?term=${term}`)
  }

  groups = (): Promise<Group[]> => {
    return this.http.get(`${apiUrl}/classification/groups`)
  }

  save = (products: Product[]): Promise<Group[]> => {
    return this.http.post(`${apiUrl}/classification/products/save`, products)
  }

  groupProducts = (groupCode: string): Promise<Product[]> => {
    return this.http.get(`${apiUrl}/classification/${groupCode}/products`)
  }

  update = (product: Product) => {
    return this.http.patch(`${apiUrl}/classification/products/update`, product)
  }

  declassify = (barcode: string) => {
    return this.http.post(`${apiUrl}/classification/products/declassify`, { barcode })
  }
}
