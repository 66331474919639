import { Flex, Table, Tag, TagCloseButton, TagLabel, Tbody, Td, Tr } from '@chakra-ui/react'
import * as React from 'react'
import { Product } from '../product/entities/product'
import { ClassificationStore } from '../stores/classification'
import { GroupTag } from './group_tag'
import { ProductCheckbox } from './product_checkbox'

export const ProductList = () => {
  const products = ClassificationStore.useState((s) => s.products)
  const mappedGroups = ClassificationStore.useState((s) => s.mappedGroups)
  const mappedProducts = ClassificationStore.useState((s) => s.mappedProducts)
  const selectedProducts = ClassificationStore.useState((s) => s.selectedProducts)

  const handleSelectedProduct = (product: Product): void => {
    const existing = selectedProducts[product.barcode]
    if (existing) {
      ClassificationStore.update((s) => {
        delete s.selectedProducts[product.barcode]
        s.selectedProducts = { ...s.selectedProducts }
      })
    } else {
      ClassificationStore.update((s) => {
        s.selectedProducts[product.barcode] = product
      })
    }
  }

  const handleSelectGroup = (product: Product, groupCode: string) => {
    ClassificationStore.update((s) => {
      let updatedMappedProducts = Object.assign(s.mappedProducts, {})

      if (Object.keys(selectedProducts).length > 0) {
        for (const key of Object.keys(selectedProducts)) {
          updatedMappedProducts[key].selectedGroup = mappedGroups[groupCode]
        }
      } else {
        updatedMappedProducts[product.barcode].selectedGroup = mappedGroups[groupCode]
      }

      s.mappedProducts = updatedMappedProducts
      s.selectedProducts = {}
    })
  }

  const removeMappedProduct = (product: Product) => {
    ClassificationStore.update((s) => {
      let updatedMappedProducts = Object.assign(s.mappedProducts, {})
      updatedMappedProducts[product.barcode].selectedGroup = null
      s.mappedProducts = updatedMappedProducts
    })
  }

  if (!mappedProducts) {
    return null
  }

  return (
    <React.Fragment>
      <Flex mt='3' direction="column">
        <Table size="xs">
          <Tbody>
            {products &&
              products.map((p) => {
                const mapped = mappedProducts[p.barcode]
                if (!mapped) {
                  return null
                }
                const wasClassified = mapped.selectedGroup != null
                return (
                  <Tr key={p.barcode}>
                    <Td>
                      <ProductCheckbox product={p} onSelectProduct={handleSelectedProduct} />
                    </Td>
                    <Td>
                      <Flex>
                        {wasClassified ? (
                          <Tag variant="outline" margin="2" marginRight="2" colorScheme="green">
                            <TagLabel>{mapped.selectedGroup?.descricao}</TagLabel>
                            <TagCloseButton onClick={() => removeMappedProduct(p)} />
                          </Tag>
                        ) : (
                          p.groups &&
                          p.groups.map((g) => (
                            <GroupTag
                              key={g}
                              groupCode={g}
                              onSelectGroup={() => handleSelectGroup(p, g)}
                            />
                          ))
                        )}
                      </Flex>
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </Flex>
    </React.Fragment>
  )
}
