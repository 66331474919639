import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat'
import { ClassificationStore } from '../stores/classification'

export const TotalProductsToClassify = () => {
  const total = ClassificationStore.useState((s) => s.total)
  return (
    <Stat size="md">
      <StatLabel fontSize="12">Total</StatLabel>
      <StatNumber>{total}</StatNumber>
    </Stat>
  )
}
