import {
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast
} from '@chakra-ui/react'
import * as React from 'react'
import { useHistory } from 'react-router'
import { AuthProvider } from '../auth/provider'
import { AppConstants } from '../infra/constants'
import { dt, locator } from '../infra/locator'
import { AppRoutes } from '../routes'

export const LoginScreen = () => {
  const history = useHistory()
  const toast = useToast()
  const [credentials, setCredentials] = React.useState({ username: '', password: '' })

  const authenticate = async () => {
    const authProvider = locator.get<AuthProvider>(dt.AuthProvider)
    try {
      const response = await authProvider.authenticate(credentials)

      if (response && response.token && response.user) {
        localStorage.setItem(AppConstants.Token, response.token)
        localStorage.setItem(AppConstants.User, JSON.stringify(response.user))
        history.replace(AppRoutes.Classication)
      }
    } catch (error) {
      toast({
        title: 'Erro ao logar',
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  return (
    <Container height="100vh" width="100vw" alignContent="center" paddingTop="10">
      <Flex direction="column" alignSelf="center" marginTop="20">
        <Heading textAlign="center">Atlas Admin</Heading>
        <FormControl id="email" marginTop="10">
          <FormLabel>Email</FormLabel>
          <Input
            value={credentials.username}
            onChange={(e) =>
              setCredentials({
                ...credentials,
                username: e.target.value
              })
            }
            type="email"
          />
        </FormControl>
        <FormControl id="Password" marginTop="4">
          <FormLabel>Password</FormLabel>
          <Input
            value={credentials.password}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                authenticate()
              }
            }}
            onChange={(e) =>
              setCredentials({
                ...credentials,
                password: e.target.value
              })
            }
            type="password"
          />
        </FormControl>
        <Button onClick={authenticate} marginTop="4" colorScheme="green">
          Logar
        </Button>
      </Flex>
    </Container>
  )
}
