import { apiUrl } from '../infra/hostname'
import { HttpProvider } from '../infra/http'
import { dt, locator } from '../infra/locator'

interface AuthCredentials {
  username: string
  password: string
}

export interface AuthenticateResponse {
  token: string
  user: {
    active: boolean
    admin: boolean
    email: string
    name: string
    role: string
    stores: string[]
    tenantId: string
    _id: string
    _key: string
  }
}

export class AuthProvider {
  private http

  constructor() {
    this.http = locator.get<HttpProvider>(dt.HttpProvider)
  }

  authenticate = (credentials: AuthCredentials): Promise<AuthenticateResponse> => {
    return this.http.post(`${apiUrl}/authenticate`, credentials)
  }
}
