import { Tag } from '@chakra-ui/react'
import * as React from 'react'
import { ClassificationStore } from '../stores/classification'

interface GroupTagProps {
  groupCode: string
  onSelectGroup: Function
}

export const GroupTag: React.FunctionComponent<GroupTagProps> = ({ groupCode, onSelectGroup }) => {
  const mappedGroups = ClassificationStore.useState((s) => s.mappedGroups)
  const group = mappedGroups[groupCode]

  if (!group) return null

  return (
    <Tag
      cursor="pointer"
      onClick={() => {
        onSelectGroup(groupCode)
      }}
      variant="outline"
      colorScheme="gray"
      margin="2"
      marginRight="2">
      {group.descricao}
    </Tag>
  )
}
