import { AuthProvider } from '../auth/provider'
import { ClassificationProvider } from '../classification/providers/classification'
import { HttpProvider } from './http'

class Container {
  services: Record<symbol | string, any> = {}

  bind = <T>(key: any, service: T): void => {
    this.services[key] = service
  }

  get = <T>(key: symbol): T => {
    return this.services[key as any] as T
  }
}

export const dt = {
  AuthProvider: Symbol.for('authProvider'),
  HttpProvider: Symbol.for('httpProvider'),
  ClassificationProvider: Symbol.for('classificationProvider')
}

export const locator = new Container()
export const registerServices = (): void => {
  locator.bind<HttpProvider>(dt.HttpProvider, new HttpProvider())
  locator.bind<AuthProvider>(dt.AuthProvider, new AuthProvider())
  locator.bind<ClassificationProvider>(dt.ClassificationProvider, new ClassificationProvider())
}
