import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import * as React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './app'
import { registerServices } from './infra/locator'
import './index.css'

const rootElement = document.getElementById('root')

registerServices()

render(
  <ChakraProvider
    theme={extendTheme({
      fonts: {
        heading: 'Prompt',
        body: 'Roboto'
      }
    })}>
    <Router>
      <App />
    </Router>
  </ChakraProvider>,
  rootElement
)
