import { AppConstants } from './constants'
import { apiUrl } from './hostname'

export interface GraphQLResponse<T> {
  errors: string[]
  data: T
}

export class HttpProvider {
  query = async <T>(request: string, payload?: Record<string, any>): Promise<T> => {
    return this.post<GraphQLResponse<T>>(`${apiUrl}/q`, {
      query: request,
      variables: payload
    }).then((r) => r.data)
  }

  get = <T>(url: string): Promise<T> => {
    return this.request<T>(url, 'GET')
  }

  post = <T>(url: string, payload: Record<string, any>): Promise<T> => {
    return this.request<T>(url, 'POST', payload)
  }

  put = <T>(url: string, payload: Record<string, any>): Promise<T> => {
    return this.request<T>(url, 'PUT', payload)
  }

  patch = <T>(url: string, payload: Record<string, any>): Promise<T> => {
    return this.request<T>(url, 'PATCH', payload)
  }

  checkAuthError = (response: Response): void => {
    // TODO: create this method
  }

  insertTokenIntoHeaders = (headers: Headers): void => {
    const token = localStorage.getItem(AppConstants.Token)

    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    if (token) {
      headers.append('Authorization', token)
    }
  }

  request = async <T>(url: string, method: string, payload?: Record<string, any>): Promise<T> => {
    const headers = new Headers()
    this.insertTokenIntoHeaders(headers)

    console.log(url)

    const response = await fetch(`${url}`, {
      method,
      headers,
      mode: 'cors',
      credentials: 'omit',
      body: JSON.stringify(payload)
    })

    if (response.status > 200) {
      throw new Error(await response.json())
    }

    const jsonResponse = await response.json()
    return jsonResponse.data as T
  }
}
