import { Divider, Flex, List, ListItem, Text } from '@chakra-ui/layout'
import { Tag } from '@chakra-ui/tag'
import { useToast } from '@chakra-ui/toast'
import { Fragment } from 'react'
import { Group } from '../group/entities/group'
import { ClassificationStore } from '../stores/classification'

export const GroupList = () => {
  const toast = useToast()

  const groups = ClassificationStore.useState((s) => s.groups)
  const mappedProducts = ClassificationStore.useState((s) => s.mappedProducts)
  const selected = ClassificationStore.useState((s) => s.selectedGroupCode)
  const selectedProducts = ClassificationStore.useState((s) => s.selectedProducts)

  const applyGroupToProducts = (group: Group) => {
    if (Object.keys(selectedProducts).length === 0) {
      return toast({
        description: 'Selecione ao menos um produto',
        status: 'error'
      })
    }

    const updatedMappedProducts = { ...mappedProducts }

    for (const [key] of Object.entries(selectedProducts)) {
      const product = { ...updatedMappedProducts[key] }
      product.selectedGroup = { ...group }
      updatedMappedProducts[key] = product
    }

    ClassificationStore.update((s) => {
      s.selectedGroupCode = group.grupo_codigo
      s.mappedProducts = updatedMappedProducts
      s.selectedProducts = {}
    })
  }

  return (
    <List>
      {groups &&
        groups.map((g, i) => {
          const isTopGroup = g.classificacao.length === 8
          const isSelected = selected && selected === g.grupo_codigo
          return (
            <Fragment key={g.grupo_codigo}>
              {g.classificacao.length === 8 && i > 0 ? <Divider height="3px" /> : null}
              <ListItem
                onClick={() =>
                  ClassificationStore.update((s) => {
                    s.selectedGroupCode = g.grupo_codigo
                  })
                }
                onDoubleClick={() => applyGroupToProducts(g)}
                bg={isSelected ? 'green.400' : 'white'}
                color={isSelected ? 'white' : 'black'}
                cursor="auto"
                padding="3"
                borderRadius="md">
                <Flex justifyContent="space-between">
                  <Text fontWeight={isTopGroup ? 'bold' : 'normal'}>
                    {g.classificacao} - {g.descricao}
                  </Text>
                  <Tag
                    colorScheme={isSelected ? 'white' : 'gray'}
                    marginRight="2"
                    variant="outline">
                    {g.grupo_codigo}
                  </Tag>
                </Flex>
              </ListItem>
            </Fragment>
          )
        })}
    </List>
  )
}
