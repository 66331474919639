import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ClassificationScreen } from './classification/screen'
import { LoginScreen } from './login/screen'
import { ProtectedRoute } from './protected'
import { AppRoutes } from './routes'

export const App = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={AppRoutes.Classication}>
          <ProtectedRoute>
            <ClassificationScreen />
          </ProtectedRoute>
        </Route>
        <Route exact path={AppRoutes.Login}>
          <LoginScreen />
        </Route>
      </Switch>
    </React.Fragment>
  )
}
