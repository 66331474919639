import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack
} from '@chakra-ui/react'
import * as React from 'react'
import { dt, locator } from '../infra/locator'
import { Product } from '../product/entities/product'
import { ClassificationStore } from '../stores/classification'
import { useDebounce } from '../utils/debounce'
import { ClassificationProvider } from './providers/classification'

interface ProductSearchResult {
  barcode: string
  brand: string
  complement: string
  description: string
  group: string
  group_description: string
}

export const ProductSearchDrawer = () => {
  const isProductSearchDrawerOpen = ClassificationStore.useState((s) => s.isProductSearchDrawerOpen)
  const classProvider = locator.get<ClassificationProvider>(dt.ClassificationProvider)

  const [products, setProducts] = React.useState<ProductSearchResult[]>([])
  const [busy, setBusy] = React.useState(false)
  const [term, setTerm] = React.useState('')
  const debouncedTerm: string = useDebounce(term, 500)

  React.useEffect(() => {
    const searchProducts = async () => {
      try {
        setBusy(true)
        const results: any = await classProvider.search(debouncedTerm)
        setProducts(results)
      } catch (error) {
        console.log(error)
      } finally {
        setBusy(false)
      }
    }
    if (debouncedTerm.length > 0) {
      searchProducts()
    }
  }, [debouncedTerm])

  return (
    <Drawer
      isOpen={isProductSearchDrawerOpen}
      placement="right"
      onClose={() => {
        ClassificationStore.update((s) => {
          s.isProductSearchDrawerOpen = false
        })
      }}
      size="lg">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton
            mt="2"
            onClick={() =>
              ClassificationStore.update((s) => {
                s.isProductSearchDrawerOpen = false
              })
            }
          />
          <DrawerHeader p="3" mr="12">
            <Input
              autoFocus
              placeholder="Nome do produto"
              onChange={(e) => setTerm(e.target.value)}
              value={term}
            />
          </DrawerHeader>
          <DrawerBody p="1">
            <Table variant="simple" size="sm">
              <Tbody>
                {products &&
                  products.map((p) => {
                    return busy ? (
                      <Tr key={p.barcode}>
                        <Td colSpan={2}>
                          <Flex justifyContent="center">
                            <CircularProgress
                              m="2"
                              size={6}
                              isIndeterminate
                              color="green"
                              value={80}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ) : (
                      <Tr key={p.barcode}>
                        <Td>
                          <VStack alignItems="flex-start">
                            <Text>{p.description}</Text>
                            <Text fontSize="xs" color="gray.500">
                              {p.barcode} - {p.complement} - {p.brand}
                            </Text>
                          </VStack>
                        </Td>
                        <Td>
                          {p.group} - {p.group_description}
                        </Td>
                      </Tr>
                    )
                  })}
              </Tbody>
            </Table>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
