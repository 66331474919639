import {
  Button,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast
} from '@chakra-ui/react'
import * as React from 'react'
import { dt, locator } from '../infra/locator'
import { Product } from '../product/entities/product'
import { ClassificationProvider } from './providers/classification'

interface EditProductProps {
  product: Product | null
  onDone: () => void
  onUpdate: () => void
}

export const EditProductDrawer: React.FC<EditProductProps> = (props) => {
  const { onDone, onUpdate } = props

  const toast = useToast()
  const classProvider = locator.get<ClassificationProvider>(dt.ClassificationProvider)
  const [product, setProduct] = React.useState(props.product)
  const [busy, setBusy] = React.useState(false)

  if (!product) {
    return null
  }

  const updateProduct = async () => {
    try {
      setBusy(true)
      await classProvider.update(product)
      toast({
        description: 'Produto salvo com sucesso',
        status: 'success'
      })
      onUpdate()
      onDone()
    } catch (error) {
      toast({
        description: error.message,
        status: 'error'
      })
    } finally {
      setBusy(false)
    }
  }

  return (
    <Drawer isOpen={Boolean(product)} placement="left" onClose={onDone} size="md">
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton onClick={onDone} />
          <DrawerHeader p="3" mr="12">
            <Flex>
              <Button onClick={updateProduct} colorScheme="green">
                Salvar
              </Button>
            </Flex>
            <Text align="center" mt="8">
              {product.description}
            </Text>
          </DrawerHeader>
          <DrawerBody>
            {busy ? (
              <Flex justifyContent="center">
                <CircularProgress m="10" isIndeterminate color="green" value={80} />
              </Flex>
            ) : (
              <React.Fragment>
                <FormControl>
                  <FormLabel>Código de Barras</FormLabel>
                  <Input
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        barcode: e.target.value
                      })
                    }}
                    value={product.barcode}
                  />
                </FormControl>
                <FormControl mt="2">
                  <FormLabel>Descrição</FormLabel>
                  <Input
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        description: e.target.value
                      })
                    }}
                    value={product.description}
                  />
                </FormControl>
                <FormControl mt="2">
                  <FormLabel>Complemento</FormLabel>
                  <Input
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        complement: e.target.value
                      })
                    }}
                    value={product.complement}
                  />
                </FormControl>
                <FormControl mt="2">
                  <FormLabel>Marca</FormLabel>
                  <Input
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        brand: e.target.value
                      })
                    }}
                    value={product.brand}
                  />
                </FormControl>
                <FormControl mt="2">
                  <FormLabel>Ncm</FormLabel>
                  <Input
                    onChange={(e) => {
                      setProduct({
                        ...product,
                        ncm: e.target.value
                      })
                    }}
                    value={product.ncm}
                  />
                </FormControl>
              </React.Fragment>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
