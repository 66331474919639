import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  IconButton,
  useToast
} from '@chakra-ui/react'
import * as React from 'react'
import { useEffect } from 'react'
import { dt, locator } from '../infra/locator'
import { Product } from '../product/entities/product'
import { ClassificationStore } from '../stores/classification'
import { GroupList } from './group_list'
import { GroupProducts } from './group_products'
import { ProductList } from './product_list'
import { ProductSearchDrawer } from './product_search'
import { ClassificationProvider } from './providers/classification'
import { TotalProductsToClassify } from './total'

export const ClassificationScreen = () => {
  const toast = useToast()

  const classProvider = locator.get<ClassificationProvider>(dt.ClassificationProvider)
  const mappedProducts = ClassificationStore.useState((s) => s.mappedProducts)
  const selectedGroupCode = ClassificationStore.useState((s) => s.selectedGroupCode)
  const products = ClassificationStore.useState((s) => s.products)

  const [loaded, setLoaded] = React.useState(false)
  const [busy, setBusy] = React.useState(false)

  const fetchTotal = async () => {
    const response = await classProvider.total()
    ClassificationStore.update((s) => {
      s.total = response.total
    })
  }

  const fetchNextProducts = async () => {
    const products = await classProvider.next()
    setBusy(false)
    const mappedProducts = products.reduce((acc, p) => {
      acc[p.barcode] = p
      return acc
    }, {} as any)
    ClassificationStore.update((s) => {
      s.products = products
      s.mappedProducts = mappedProducts
    })
  }

  const fetchGroups = async () => {
    const groups = await classProvider.groups()
    const mappedGroups = groups.reduce((acc, g) => {
      acc[g.grupo_codigo] = g
      return acc
    }, {} as any)
    ClassificationStore.update((s) => {
      s.groups = groups
      s.mappedGroups = mappedGroups
    })
  }

  const selectAllProducts = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const updatedSelectedProducts: Record<string, Product> = {}
      for (const product of products) {
        updatedSelectedProducts[product.barcode] = product
      }
      ClassificationStore.update((s) => {
        s.selectedProducts = updatedSelectedProducts
      })
    } else {
      ClassificationStore.update((s) => {
        s.selectedProducts = {}
      })
    }
  }

  const confirmClassification = async () => {
    const productsToSave: Product[] = []
    for (const [, value] of Object.entries(mappedProducts)) {
      if (value.selectedGroup != null) {
        productsToSave.push(value)
      }
    }
    try {
      setBusy(true)
      await classProvider.save(productsToSave)
      fetchNextProducts()
      fetchTotal()
    } catch (error) {
      toast({
        description: error.message,
        status: 'error'
      })
    }
  }

  useEffect(() => {
    Promise.all([fetchGroups(), fetchTotal(), fetchNextProducts()]).then(() => setLoaded(true))
  }, [])

  return (
    <Flex direction="column" padding="1">
      <ProductSearchDrawer />
      {!loaded ? (
        <Flex justifyContent="center">
          <CircularProgress isIndeterminate value={80} color="green" />
        </Flex>
      ) : (
        <Flex>
          <Box flex="1">
            <Box maxHeight="calc(100vh - 68px)" overflowY="scroll" padding="2">
              <GroupList />
            </Box>
            <GroupProducts />
            <Button
              onClick={() => {
                if (!selectedGroupCode) {
                  return toast({
                    description: 'Selecione um grupo',
                    status: 'error'
                  })
                }
                ClassificationStore.update((s) => {
                  s.isProductDrawerOpen = true
                })
              }}
              m="2"
              width="full">
              Ver Produtos do Grupo
            </Button>
          </Box>
          <Box flexDirection="column" flex="2" p="2" ml="4">
            <Flex justifyContent="space-between" mb="2">
              <Flex flex="5">
                <Checkbox colorScheme="green" onChange={selectAllProducts} marginBottom="4">
                  Selecionar todos
                </Checkbox>
              </Flex>
              <Flex flex="4">
                <TotalProductsToClassify />
              </Flex>
              <Flex flex="1">
                <IconButton
                  mr="2"
                  aria-label="Pesquisar"
                  onClick={() =>
                    ClassificationStore.update((s) => {
                      s.isProductSearchDrawerOpen = true
                    })
                  }
                  icon={<SearchIcon />}></IconButton>
                <Button onClick={confirmClassification} colorScheme="green">
                  Confirmar
                </Button>
              </Flex>
            </Flex>
            <Box height="calc(100vh - 90px)" overflowY="auto">
              {busy ? (
                <CircularProgress value={80} color="green" isIndeterminate />
              ) : (
                <ProductList />
              )}
            </Box>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
