export let hostname = ''
export let origin = ''

if (typeof window !== 'undefined') {
  origin = window.location.origin
  hostname = window.location && window.location.hostname
}

export const isProd = (): boolean => hostname.includes('atlasbi.com.br')

export const apiUrl = hostname.includes('atlasbi.com.br')
  ? 'https://api.atlasbi.com.br'
  : 'http://localhost:4000'
