import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AppRoutes } from './routes'

export const ProtectedRoute: React.FC = (props) => {
  let storedToken = localStorage.getItem('token')
  if (!storedToken) {
    return <Redirect to={AppRoutes.Login} />
  }
  return <Route {...props}>{props.children}</Route>
}
