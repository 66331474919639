import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import * as React from 'react'
import { dt, locator } from '../infra/locator'
import { Product } from '../product/entities/product'
import { ClassificationStore } from '../stores/classification'
import { EditProductDrawer } from './product_edit'
import { ClassificationProvider } from './providers/classification'

export const GroupProducts = () => {
  const toast = useToast()
  const { onClose } = useDisclosure()

  const classProvider = locator.get<ClassificationProvider>(dt.ClassificationProvider)
  const selectedGroupCode = ClassificationStore.useState((s) => s.selectedGroupCode)
  const isProductDrawerOpen = ClassificationStore.useState((s) => s.isProductDrawerOpen)
  const mappedGroups = ClassificationStore.useState((s) => s.mappedGroups)

  const [products, setProducts] = React.useState<Product[]>([])
  const [activeProduct, setActiveProduct] = React.useState<Product | null>(null)
  const [busy, setBusy] = React.useState('')

  const fetchGroupProducts = async () => {
    const response = await classProvider.groupProducts(selectedGroupCode)
    setProducts(response)
  }

  const declassifyProduct = async (product: Product) => {
    try {
      setBusy(product.barcode)
      await classProvider.declassify(product.barcode)
      await fetchGroupProducts()
    } catch (error) {
      toast({
        description: error.message,
        status: 'error'
      })
    } finally {
      setBusy('')
    }
  }

  React.useEffect(() => {
    if (isProductDrawerOpen) {
      fetchGroupProducts()
    } else {
      setProducts([])
    }
  }, [isProductDrawerOpen, selectedGroupCode])

  return (
    <React.Fragment>
      {activeProduct && (
        <EditProductDrawer
          onUpdate={fetchGroupProducts}
          onDone={() => setActiveProduct(null)}
          product={activeProduct}
        />
      )}
      <Drawer isOpen={isProductDrawerOpen} placement="left" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton
              onClick={() => {
                ClassificationStore.update((s) => {
                  s.isProductDrawerOpen = false
                })
              }}
            />
            <DrawerHeader p="3" mr="12">
              {mappedGroups[selectedGroupCode]?.descricao}
            </DrawerHeader>
            <DrawerBody p="0">
              <Table variant="simple" size="sm">
                <Tbody>
                  {products &&
                    products.map((p) => {
                      return busy && busy === p.barcode ? (
                        <Tr key={p.barcode}>
                          <Td colSpan={2}>
                            <Flex justifyContent="center">
                              <CircularProgress
                                m="2"
                                size={6}
                                isIndeterminate
                                color="green"
                                value={80}
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      ) : (
                        <Tr key={p.barcode}>
                          <Td>
                            <VStack alignItems="flex-start">
                              <Text>{p.description}</Text>
                              <Text fontSize="xs" color="gray.500">
                                {p.barcode} - {p.complement} - {p.brand}
                              </Text>
                            </VStack>
                          </Td>
                          <Td>
                            <IconButton
                              onClick={() => setActiveProduct(p)}
                              mr="2"
                              aria-label="Remover"
                              icon={<EditIcon />}
                            />
                            <IconButton
                              onClick={() => declassifyProduct(p)}
                              aria-label="Remover"
                              icon={<DeleteIcon />}
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </React.Fragment>
  )
}
