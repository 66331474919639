import { Store } from 'pullstate'
import { Group } from '../group/entities/group'
import { Product } from '../product/entities/product'

interface ClassificationState {
  total: number
  products: Product[]
  mappedProducts: Record<string, Product>
  groups: Group[]
  mappedGroups: Record<string, Group>
  selectedProducts: Record<string, Product>
  selectedGroupCode: string
  isProductDrawerOpen: boolean
  isProductSearchDrawerOpen: boolean
}

export const ClassificationStore = new Store<ClassificationState>({
  total: 0,
  products: [],
  mappedProducts: {},
  groups: [],
  mappedGroups: {},
  selectedProducts: {},
  selectedGroupCode: '',
  isProductDrawerOpen: false,
  isProductSearchDrawerOpen: false
})
