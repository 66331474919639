import { Checkbox, Flex, FormControl } from '@chakra-ui/react'
import * as React from 'react'
import { Product } from '../product/entities/product'
import { ClassificationStore } from '../stores/classification'

interface ProductCheckboxProps {
  product: Product
  onSelectProduct: (product: Product) => void
}

export const ProductCheckbox: React.FC<ProductCheckboxProps> = ({ product, onSelectProduct }) => {
  const selectedProducts = ClassificationStore.useState((s) => s.selectedProducts)
  const isChecked = selectedProducts[product.barcode] != null
  return (
    <Checkbox colorScheme="green" isChecked={isChecked} onChange={() => onSelectProduct(product)}>
      <FormControl>
        <Flex>{product.description}</Flex>
      </FormControl>
    </Checkbox>
  )
}
